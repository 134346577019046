/*
    GloverSure Foundation Template - CSS in this file is divided into sections: Header, Slideshow etc.

    Styles for other pages like News, Products etc. are split into individual SCSS files in the 'Modules directory'.
*/
/* ===========================] General [======================== */
.button {
  display: inline-block;
  background-color: #087649;
  color: #fff;
  padding: 10px 25px;
  margin-top: 0.6em; }
  .button:hover {
    background-color: #065e3a; }

body,
html {
  background-color: white;
  font-family: "Open Sans", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Slab", serif;
  font-weight: bolder; }

p,
ul {
  font-weight: lighter;
  font-size: 16px; }

a {
  color: #087649; }
  a:focus, a:hover {
    color: #065e3a; }

.grid-x {
  width: 90%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row wrap; }
  .grid-x.expanded {
    max-width: 100%;
    width: 100%; }
  .grid-x.full {
    max-width: 100%;
    width: 100%; }

.g-recaptcha {
  margin-bottom: 1em; }

.scrollup {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: none;
  background-color: #FFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  color: #0A0A0A;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  font-weight: bold; }

#cookie-notice {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #087649;
  text-align: center;
  padding: 1em;
  color: #fff;
  z-index: 999; }
  #cookie-notice p {
    margin-bottom: 10px;
    font-size: 0.9em; }
  #cookie-notice a {
    background-color: #05462b;
    padding: 0.7em 1em;
    display: inline-block;
    font-size: 0.8em;
    border-radius: 2px;
    color: #fff; }

.input-group {
  display: block; }

/* ===========================] End General [======================== */
/* ===========================] Header [======================== */
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 3;
  transition: 0.4s;
  padding: 20px 0px;
  border-bottom: solid 3px #992E2E; }
  header.header-scrolled {
    /* Styles for header when fixed + scrolled here, logo shrink etc */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05); }

.header-left {
  transition: 0.4s; }
  .header-left img {
    width: 220px;
    transition: 0.35s; }
    @media screen and (max-width: 1050px) {
      .header-left img {
        width: 180px; } }
    @media screen and (max-width: 650px) {
      .header-left img {
        width: 145px; } }

.header-right .menu li a {
  color: #0e0e0e;
  transition: 0.2s;
  font-size: 15px; }
  .header-right .menu li a:hover {
    color: #087649; }

.header-right .menu-icon::after {
  background: #087649;
  box-shadow: 0 7px 0 #087649, 0 14px 0 #087649; }

.dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
  border: inset 4px;
  border-color: #087649 transparent transparent;
  right: 8px;
  margin-top: -2px; }

.dropdown.menu .is-active > a {
  color: #087649; }

@media print, screen and (min-width: 65.625em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu > li > ul.menu.vertical.large-horizontal.align-right.submenu.is-dropdown-submenu.first-sub.dropdown.js-dropdown-active {
    left: 100% !important;
    right: auto !important;
    top: 0px !important; } }

.accordion-menu.align-right .is-accordion-submenu-parent > a::after {
  right: 1em;
  left: auto;
  border: inset 5px;
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #fff transparent transparent; }

/* ===========================] End Header [======================== */
/* ===========================] Slideshow [======================== */
#slideshow {
  position: relative; }
  #slideshow .slideshow-contact {
    position: absolute;
    left: 8%;
    bottom: 0;
    z-index: 1; }
    @media screen and (max-width: 650px) {
      #slideshow .slideshow-contact div {
        display: none; } }
    #slideshow .slideshow-contact div {
      float: left;
      border: solid 1px rgba(255, 255, 255, 0.5);
      border-bottom: none;
      padding: 15px 30px; }
      #slideshow .slideshow-contact div:first-of-type {
        border-right: none; }
      #slideshow .slideshow-contact div a {
        color: #fff; }
        #slideshow .slideshow-contact div a i {
          color: #087649;
          margin-right: 10px; }
  #slideshow .slide {
    background-color: #000;
    height: 630px; }
    @media screen and (max-width: 650px) {
      #slideshow .slide {
        height: 550px; } }
    #slideshow .slide img {
      opacity: 0.85;
      height: 100%;
      object-fit: cover; }
  #slideshow .overlay {
    position: absolute;
    top: 50%;
    left: 8%;
    transform: translateY(-20%);
    max-width: 700px;
    width: 84%;
    margin: 0 auto;
    opacity: 0;
    transition: 0.5s;
    transition-delay: 0.6s; }
    #slideshow .overlay p:first-of-type,
    #slideshow .overlay h1,
    #slideshow .overlay h2,
    #slideshow .overlay h3,
    #slideshow .overlay h4,
    #slideshow .overlay h5,
    #slideshow .overlay h6 {
      font-family: "Roboto Slab", serif;
      color: #fff;
      font-weight: bolder;
      font-size: 44px;
      margin-bottom: 0.4em; }
    #slideshow .overlay p {
      color: #fff;
      font-weight: lighter;
      font-size: 20px; }
      #slideshow .overlay p:last-of-type {
        margin: 0px; }
    #slideshow .overlay a {
      display: inline-block;
      background-color: #087649;
      color: #fff;
      padding: 10px 25px;
      margin-top: 0.6em;
      font-size: 18px; }
      #slideshow .overlay a:hover {
        background-color: #065e3a; }
    #slideshow .overlay.animated {
      /* Animates in the Slideshow text */
      opacity: 1;
      transform: translateY(-50%); }
  #slideshow .owl-prev,
  #slideshow .owl-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    #slideshow .owl-prev i,
    #slideshow .owl-next i {
      color: #fff;
      padding: 10px; }
  #slideshow .owl-prev {
    left: 30px; }
  #slideshow .owl-next {
    right: 30px; }
  #slideshow .owl-dots {
    position: absolute;
    z-index: 1;
    bottom: 30px;
    right: 30px; }
    #slideshow .owl-dots .owl-dot {
      width: 12px;
      height: 12px;
      background: #fff;
      margin-left: 10px;
      border-radius: 50%;
      opacity: 0.4;
      transition: 0.35s;
      outline: none; }
      #slideshow .owl-dots .owl-dot:hover {
        opacity: 1; }
      #slideshow .owl-dots .owl-dot.active {
        opacity: 1; }

/* ===========================] End Slideshow [======================== */
#page-content {
  padding: 80px 0px; }
  @media screen and (max-width: 1050px) {
    #page-content {
      padding: 60px 0px; } }
  @media screen and (max-width: 650px) {
    #page-content {
      padding: 40px 0px; } }
  #page-content h1,
  #page-content h2,
  #page-content h3,
  #page-content h4,
  #page-content h5,
  #page-content h6 {
    margin-bottom: 15px; }
  #page-content h2 {
    font-size: 26px; }
  #page-content h3 {
    font-size: 20px; }
  #page-content h4 {
    font-size: 18px; }

#content {
  padding: 90px 0px; }
  @media screen and (max-width: 1050px) {
    #content {
      padding: 70px 0px; } }
  @media screen and (max-width: 650px) {
    #content {
      padding: 50px 0px; } }
  #content .grid-x {
    position: relative; }
    #content .grid-x .home-news-title {
      display: inline-block;
      background: #087649;
      transition: 0.4s;
      z-index: 1;
      font-size: 17px;
      color: #fff !important;
      font-weight: normal;
      padding: 22px;
      font-family: "Roboto Slab", serif; }
      @media screen and (min-width: 1050px) {
        #content .grid-x .home-news-title {
          position: absolute;
          width: 28.8%;
          top: -28%;
          right: 1.5%; } }
      @media screen and (max-width: 1050px) {
        #content .grid-x .home-news-title {
          margin-top: 2em;
          margin-bottom: 0;
          padding: 15px 25px;
          width: 100%; } }
      #content .grid-x .home-news-title i {
        font-size: 28px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 12px;
        color: #065e3a; }
      #content .grid-x .home-news-title:hover {
        background: #098452; }
    #content .grid-x .latest-news-panel {
      border: 1px solid #efefef;
      border-top: none;
      padding: 25px 25px 0; }
  @media screen and (min-width: 1050px) {
    #content .large-8 {
      padding-right: 6%;
      border-right: solid 1px rgba(0, 0, 0, 0.1); } }
  #content .large-8 h1,
  #content .large-8 h2,
  #content .large-8 h3 {
    font-size: 35px;
    margin-bottom: 0.8em;
    font-weight: bolder; }
  @media screen and (min-width: 1050px) {
    #content .large-4 {
      padding-left: 3%; } }
  #content .large-4 p {
    font-size: 14px;
    margin-bottom: 10px; }
  #content .large-4 a {
    font-size: 15px;
    color: #087649; }
  #content .large-4 .latest-news-item {
    margin-bottom: 30px; }
    #content .large-4 .latest-news-item .cell.auto {
      padding-left: 4%; }
    @media screen and (max-width: 650px) {
      #content .large-4 .latest-news-item .cell.auto {
        padding-left: 0; }
      #content .large-4 .latest-news-item .cell.shrink {
        display: none; } }

#blocks {
  position: relative; }
  @media screen and (max-width: 650px) {
    #blocks .grid-x {
      width: 100% !important; } }
  @media screen and (min-width: 650px) {
    #blocks {
      background: #087649; } }
  #blocks::after {
    content: '';
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: 0; }
    @media screen and (min-width: 650px) {
      #blocks::after {
        background-color: #065e3a; } }
  #blocks .cell {
    position: relative;
    z-indeX: 1;
    padding: 5%;
    color: #fff; }
    @media screen and (max-width: 650px) {
      #blocks .cell {
        padding: 8%; } }
    #blocks .cell h1,
    #blocks .cell h2,
    #blocks .cell h3,
    #blocks .cell h4,
    #blocks .cell h5,
    #blocks .cell h6 {
      margin-bottom: 0.8em; }
    #blocks .cell p {
      font-size: 15px; }
    #blocks .cell a {
      display: inline-block;
      background-color: #087649;
      color: #fff;
      padding: 10px 25px;
      margin-top: 0.6em; }
      #blocks .cell a:hover {
        background-color: #065e3a; }
  #blocks .cell.services {
    background-color: #087649; }
    @media screen and (min-width: 650px) {
      #blocks .cell.services {
        padding-left: 0px; } }
    #blocks .cell.services a {
      background-color: #065e3a; }
  #blocks .cell.about-us {
    background-color: #065e3a; }
    @media screen and (min-width: 650px) {
      #blocks .cell.about-us {
        padding-right: none; } }

#more-info {
  padding: 90px 0px; }
  @media screen and (max-width: 1050px) {
    #more-info {
      padding: 70px 0px; } }
  @media screen and (max-width: 650px) {
    #more-info {
      padding: 50px 0px; } }
  #more-info .large-5 h1,
  #more-info .large-5 h2,
  #more-info .large-5 h3,
  #more-info .large-5 h4,
  #more-info .large-5 h5,
  #more-info .large-5 h6 {
    margin-bottom: 0.8em; }
  #more-info .large-5 i {
    color: #087649;
    font-size: 1.5em;
    margin-right: 15px; }
  #more-info .large-5 hr {
    display: block;
    margin: 40px 0px 45px;
    width: 100%;
    max-width: 100px; }

.contact-form label {
  display: inline-block;
  margin-right: 5px;
  width: 150px;
  vertical-align: middle; }

.contact-form [type='text'],
.contact-form [type='password'],
.contact-form [type='date'],
.contact-form [type='datetime'],
.contact-form [type='datetime-local'],
.contact-form [type='month'],
.contact-form [type='week'],
.contact-form [type='email'],
.contact-form [type='number'],
.contact-form [type='search'],
.contact-form [type='tel'],
.contact-form [type='time'],
.contact-form [type='url'],
.contact-form [type='color'],
.contact-form textarea {
  display: inline-block;
  border: none;
  width: calc(100% - 160px); }
  @media screen and (max-width: 650px) {
    .contact-form [type='text'],
    .contact-form [type='password'],
    .contact-form [type='date'],
    .contact-form [type='datetime'],
    .contact-form [type='datetime-local'],
    .contact-form [type='month'],
    .contact-form [type='week'],
    .contact-form [type='email'],
    .contact-form [type='number'],
    .contact-form [type='search'],
    .contact-form [type='tel'],
    .contact-form [type='time'],
    .contact-form [type='url'],
    .contact-form [type='color'],
    .contact-form textarea {
      width: 100%;
      margin: 0; } }

.contact-form .g-recaptcha {
  margin-left: 160px; }
  @media screen and (max-width: 650px) {
    .contact-form .g-recaptcha {
      margin-left: 0; } }

.contact-form input[type='submit'] {
  margin-left: 160px; }
  @media screen and (max-width: 650px) {
    .contact-form input[type='submit'] {
      margin-left: 0; } }

.contact-form .message label {
  vertical-align: top; }

.pages #more-info {
  padding: 50px 0px;
  background: #087649;
  color: #fff; }
  @media screen and (max-width: 1050px) {
    .pages #more-info {
      padding: 35px 0px; } }
  @media screen and (max-width: 650px) {
    .pages #more-info {
      padding: 15px 0px; } }
  .pages #more-info a {
    color: #fff; }
    .pages #more-info a:hover {
      text-decoration: underline;
      color: #fff; }
  .pages #more-info .large-5 i {
    color: #fff; }
  .pages #more-info .large-5 hr {
    border-bottom: 1px solid #ffffff; }
  .pages #more-info .button {
    background: #fff;
    color: #0a0a0a;
    transition: 0.5s; }
    .pages #more-info .button:hover {
      opacity: 0.9; }
  .pages #more-info .contact-form label {
    color: #fff; }

.services-grid {
  margin-top: 50px;
  padding-top: 50px;
  border-top: solid 1px rgba(0, 0, 0, 0.1); }
  .services-grid ul {
    margin: 0;
    padding: 0; }
    .services-grid ul:after {
      content: "";
      display: table;
      clear: both; }
  .services-grid ul li {
    list-style: none;
    float: left;
    width: 33.3333%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 50px; }
    @media screen and (max-width: 1000px) {
      .services-grid ul li {
        width: 50%; } }
    @media screen and (max-width: 650px) {
      .services-grid ul li {
        width: 100%;
        padding: 0; } }
    .services-grid ul li img {
      display: block;
      margin-bottom: 10px; }
    .services-grid ul li a {
      display: block;
      position: relative; }
    .services-grid ul li em {
      display: block;
      text-decoration: none;
      background: #087649;
      color: #fff;
      font-size: 17px;
      font-family: "Roboto Slab", serif;
      padding: 15px 30px;
      position: absolute;
      right: 25px;
      bottom: -25px;
      transition: 0.4s; }
    .services-grid ul li a:hover em {
      background: #065e3a; }

footer .footer-top {
  background: #222222;
  padding: 5em 0em; }
  @media screen and (max-width: 650px) {
    footer .footer-top {
      padding: 3em 0em 2.5em; } }
  footer .footer-top img {
    margin-bottom: 3em; }
  footer .footer-top ul {
    justify-content: center;
    text-transform: uppercase;
    font-family: "Roboto Slab", serif;
    font-size: 14px; }
    footer .footer-top ul li {
      text-align: center !important; }
      footer .footer-top ul li a {
        color: #a5a5a5;
        transition: 0.35s; }
        footer .footer-top ul li a:hover {
          color: #fff; }

footer .footer-bottom {
  background: #181818;
  padding: 2em 0em; }
  footer .footer-bottom p {
    margin: 0px;
    color: #a5a5a5;
    font-size: 13px; }
    footer .footer-bottom p a {
      color: #fff;
      transition: 0.35s; }
      footer .footer-bottom p a:hover {
        color: #087649; }

@media screen and (min-width: 650px) {
  .news-listing .medium-4 {
    padding-right: 4%; } }

@media screen and (max-width: 650px) {
  .news-listing .medium-4 {
    margin-bottom: 1em; } }

.news-listing {
  margin-bottom: 2em;
  padding-bottom: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.news-page-heading {
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 1em;
  padding-bottom: 1em; }

h3.news-list-heading {
  font-weight: normal;
  font-size: 1.5em; }

.news-article-info {
  margin: 0.5em 0em 1em;
  color: #992e2e;
  display: inline-block; }

.news-article-contents {
  max-width: 90%;
  margin: 0 auto; }

/* ===========================] Media Queries [======================== */
@media screen and (max-width: 1050px) {
  .menu-btn-wrap {
    display: block; }
  .mobile-menu-wrap .menu.vertical {
    flex-wrap: wrap; }
    .mobile-menu-wrap .menu.vertical li {
      flex: 0 0 100%; }
  .mobile-menu-wrap .close-button {
    display: block !important; }
  .mobile-menu-wrap {
    position: fixed;
    height: 100%;
    width: 400px;
    right: 0;
    top: 0;
    background: #087649;
    padding: 4em;
    z-index: 9999;
    overflow-y: auto;
    transform: translateX(100%);
    -webkit-transition: transform cubic-bezier(1, 0.21, 0, 1.07) 0.45s;
    -moz-transition: transform cubic-bezier(1, 0.21, 0, 1.07) 0.45s;
    transition: transform cubic-bezier(1, 0.21, 0.26, 0.98) 0.45s;
    box-shadow: none; }
    .mobile-menu-wrap ul li {
      text-align: left !important; }
      .mobile-menu-wrap ul li a {
        color: #fff !important;
        font-size: 17px !important;
        padding: 1em 0em; }
    .mobile-menu-wrap.is-active {
      transform: translateX(0); }
    .mobile-menu-wrap .close-button {
      padding: 0.5em;
      position: absolute;
      top: 1rem;
      right: 1rem;
      line-height: 21px; }
      .mobile-menu-wrap .close-button span {
        color: #fff;
        font-size: 2.5rem;
        font-weight: lighter; } }

@media screen and (max-width: 650px) {
  .mobile-menu-wrap {
    width: 100%; }
  .mobile-padding {
    padding: 0% 6%; } }

@media screen and (max-width: 550px) {
  .g-recaptcha {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0; } }

@media screen and (max-width: 475px) {
  .g-recaptcha {
    transform: scale(0.82);
    -webkit-transform: scale(0.82);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0; } }

@media screen and (max-width: 400px) {
  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0; } }

/* Module Stylesheets - Comment these out if not using the module */
/* Case Study Module Custom Styles */
.case-card-cont {
  margin-bottom: 2rem; }

.case-card {
  border: 1px solid #CBCBCB;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s; }

.case-card .case-img-wrapper {
  overflow: hidden; }

.case-card img {
  width: 100%;
  height: auto;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
  overflow: hidden; }

.case-card .case-label a {
  padding: 1rem;
  text-align: center;
  display: block;
  color: #1f1f1f;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s; }

.case-card:hover {
  border: 1px solid #fff;
  background-color: #087649; }

.case-card:hover img {
  transform: scale(1.1);
  opacity: 0.3s; }

.case-card:hover .case-label a {
  background-color: #087649;
  color: #fff; }

#news_case_big {
  display: flex;
  flex-flow: row wrap; }

#news_case_big #mainimage img {
  width: 100%; }

/* Gallery Module Custom Styles */
.gallery-main .owl-next {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-family: monospace;
  font-size: 27px;
  display: block;
  height: 30px;
  width: 30px;
  background-color: #087649;
  text-align: center;
  line-height: 28px;
  border-radius: 99999px;
  color: #fff; }

.gallery-main .owl-prev {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-family: monospace;
  font-size: 27px;
  display: block;
  height: 30px;
  width: 30px;
  background-color: #087649;
  text-align: center;
  line-height: 28px;
  border-radius: 99999px;
  color: #fff; }

.gallery-main {
  position: relative;
  padding: 0px 6%;
  margin: 0 auto 20px; }

/* News Module Custom Styles */
.news-card-cont {
  display: flex; }

.news-articles {
  display: flex;
  width: 100%;
  flex-flow: row wrap; }

.news-card-cont.column.small-12.medium-6 {
  margin-bottom: 1.8rem; }

.news-card {
  box-sizing: border-box;
  border: 1px solid #CBCBCB;
  padding: 2rem; }

/* Products Module Custom Styles */
div#breadcrumbs {
  margin-bottom: 1.5rem; }

.product-card-cont {
  margin-bottom: 2rem; }

.product-card {
  border: 1px solid #CBCBCB;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
  overflow: hidden; }

.product-card > a {
  overflow: hidden;
  display: block; }

.product-card .product-img-wrapper {
  overflow: hidden; }

.product-card img {
  width: 100%;
  height: auto;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
  overflow: hidden; }

.product-card .product-label a {
  padding: 1rem;
  text-align: center;
  display: block;
  color: #1f1f1f;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s; }

.product-card:hover {
  border: 1px solid #fff;
  background-color: #087649; }

.product-card:hover img {
  transform: scale(1.1);
  opacity: 0.3s; }

.product-card:hover .product-label a {
  background-color: #087649;
  color: #fff; }

ul.thumbs {
  list-style-type: none;
  margin: 0px; }

.product-left .owl-dots {
  text-align: center; }

.product-left .owl-dot {
  height: 8px;
  width: 8px;
  background-color: rgba(34, 39, 40, 0.4);
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s; }

.product-left .owl-dot:hover, .product-left .owl-dot.active {
  background-color: #087649; }

.product-left .owl-pagination {
  text-align: center; }
